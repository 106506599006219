const Home_Banner = () => {
  return (
    <>
      <section id="section__banner">
        <div className="banner__container grid">
            <div className="caption container">
                <h1>Learn</h1>
                <h2>your dream IT courses in simple clear steps</h2>
                <button className="button button--link m-1">Browse Courses</button>
            </div>
            <div className="categ">
                <div className="categories container">

                </div>
                <div className="categ__text container">
                    <h2>Website Design</h2>
                    <h3>Modern CSS for Astonishing Web Design</h3>
                    <h4>25 Lessons</h4>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
export default Home_Banner
