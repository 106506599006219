import { UilSearch } from '@iconscout/react-unicons';

const Topbar = () => {
  return (
    <section id="section__topbar">
        <div className="topbar__container grid">
            <div className="topbar__text container">
                <h2>Logo</h2>
            </div>
            <div className="topbar__text container">
                <h2>IT Training</h2>
                <h3>Easy, Step-By-Step Guides</h3>
            </div>
            <div className="topbar__text container">
                <input type="text" placeholder="Search for a course ..." />
                <button className='button'><UilSearch /></button>
            </div>
        </div>
    </section>

    // <div className="categ__text container">
    //     <h2>IT Training</h2>
    //     <h3>Easy, Step-By-Step Guides</h3>
    // </div>
  )
}

export default Topbar
