import React, { useEffect } from 'react';
import Prism from 'prismjs';
import 'prismjs/themes/prism.css'; // Change this to the theme you want to use

// Optionally, import Prism.js plugins and language definitions if needed
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
import 'prismjs/plugins/line-numbers/prism-line-numbers.min.js';
import 'prismjs/components/prism-python.min.js';
import 'prismjs/components/prism-bash.min.js';

// Import Clipboard.js for copy functionality
import ClipboardJS from 'clipboard';

const CodeHighlighter = ({ code, language }) => {
  useEffect(() => {
    Prism.highlightAll();
    new ClipboardJS('.copy-btn');

    // Optionally, add some user feedback for the copy button
    const copyBtn = document.querySelectorAll('.copy-btn');
    copyBtn.forEach(btn => {
      btn.addEventListener('click', () => {
        btn.textContent = 'Copied!';
        setTimeout(() => {
          btn.textContent = 'Copy';
        }, 2000);
      });
    });
  }, []);

  return (
    <div className="code-block-container">
      <button className="copy-btn" data-clipboard-target={`#code-block-${language}`}>Copy</button>
      <pre className="line-numbers">
        <code id={`code-block-${language}`} className={`language-${language}`}>
          {code}
        </code>
      </pre>
    </div>
  );
};

export default CodeHighlighter;
