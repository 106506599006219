import { useEffect } from 'react';
import Home_Banner from '../layouts/sections/Home_Banner';
import Topbar from '../layouts/sections/Topbar';
import Course from '../Course';
import Categories_Section from '../layouts/sections/Categories_Section';
import Courses_Section from '../layouts/sections/Courses_Section';

const Home = (props) => {
    useEffect(() => {
        document.title = `${props.title}`;
    });

    return(
        <>
        <main className="main">
            <Topbar /> 
            <Home_Banner />
            <Categories_Section />
            <Courses_Section />
            {/* <Course /> */}
        </main>
        </>
    )
}
export default Home;