import {useState, useEffect} from 'react';
import { baseUrl } from '../../../config/Config';
import axios from 'axios';
import {Link} from 'react-router-dom';
import { UilBookAlt, UilStar, UilArrowRight } from '@iconscout/react-unicons';
import python from './../../../../assets/img/courses/courses/python.png'
import teacher1 from './../../../../assets/img/instructors/1.jpg'

const Courses_Section = () => {
    const [courseData, setCourseData] = useState([]); 
    const [cats, setCats] = useState([]); // categories    
    
    useEffect(()=> {
        axios.get(`${baseUrl}/course/`)
        .then((response) => {
            setCourseData(response.data);
        })
        .catch(error => {
            setCourseData([]);
        });
    }, []);

    useEffect(()=> {
      axios.get(`${baseUrl}/course-category/`)
      .then((response) => {
          setCats(response.data);
      })
      .catch(error => {
          setCats([]);
      });
      
      // document.title = `Add Course - ${props.title}`;
  }, []);

    return (
      <>
        <br />
        <section id="third__section">
          <div className="section__text container">
              <h2>Featured Courses</h2>
          </div>
          <div className="section3__container grid courses">
              <div className="section3__categ grid container">

                <div className="courses__container grid">

                {courseData.map((course, index) => (
                  <Link to={`/course-detail/${course.slug}`} key={index}>
                    <div className="course__item">
                      <div className="course__box">
                        <img src={python} alt="" className="course__img" />
                        <div className="course__tag">
                          {cats.find(category => category.id === course.category)?.title || "Unknown Category"}
                        </div>
                      </div>
                      <div className="course__content">
                        <div className="course__meta">
                          <div className="course__lesson">
                            <UilBookAlt /> 25 Lessons
                          </div>
                          <div className="course__rating">
                            <UilStar className="uis" /> 3.5 (45)
                          </div>
                        </div>
                        <h3 className="course__title">{course.title}</h3>
                        <div className="course__teacher">
                          <img src={teacher1} className="course__teacher-img" />
                          <h3 className="course__teacher-title">Uwem Akpabot</h3>
                        </div>
                      </div>
                      <div className="course__more">
                        <div className="course__prices">
                          <span className="course__discount">$30</span>
                          <span className="course__price">$60</span>
                        </div>
                        <span className="course__button">
                          Course Detail <UilArrowRight className="course__button-icon" />
                        </span>
                      </div>
                    </div>
                  </Link>
                ))}
                </div>

              
              </div>
              <div className="section3__caption">
                  {/* <button className="button button--link m-1">Browse Courses</button> */}
              </div>
          </div>
      </section>
      </>
    )
  }
  export default Courses_Section
  