import {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { UilYoutube, UilAngleDown, UilTimesCircle, UilApps } from '@iconscout/react-unicons';
import eagles_logo from '../../../assets/img/logos/eagles_logo_removebg.png';
// import logo from '../../../img/logo.png';

const Header = (props) => {
    // const instructorLoginStatus = localStorage.getItem('instructorLoginStatus')

    useEffect(() => {
        const navMenu = document.getElementById('nav-menu');
        const navToggle = document.getElementById('nav-toggle');
        const navClose = document.getElementById('nav-close');

        if (navToggle) {
            navToggle.addEventListener('click', () => {
                navMenu.classList.add('show-menu');
            });
        }

        if (navClose) {
            navClose.addEventListener('click', () => {
                navMenu.classList.remove('show-menu');
            });
        }

        // Cleanup event listeners on component unmount
        return () => {
            if (navToggle) {
                navToggle.removeEventListener('click', () => {
                    navMenu.classList.add('show-menu');
                });
            }
            if (navClose) {
                navClose.removeEventListener('click', () => {
                    navMenu.classList.remove('show-menu');
                });
            }
        };
    }, []);

    useEffect(() => {
        const header = document.getElementById('header');

        const scrollHeader = () => {
            // When the scroll is greater than 50 viewport height, add the scroll-header class to the header tag
            if (window.scrollY >= 50) {
                header.classList.add('scroll-header');
            } else {
                header.classList.remove('scroll-header');
            }
        };

        window.addEventListener('scroll', scrollHeader);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('scroll', scrollHeader);
        };
    }, []);
    
    return (
        <header className="header" id="header">
            <nav className="nav container">
                <Link to="/" className="nav__logo logo__light"> 
                    {/* <img src={eagles_logo} alt="logo-light" /> */}
                    <span>Z</span>yzics
                </Link>

                <div className="nav__menu" id="nav-menu">
                    <ul className="nav__list">                        
                        <li className="nav__item">
                            <Link to="/" className="nav__link active-link">Home</Link>
                        </li>
                        <li className="nav__item">
                            <Link to="/" className="nav__link">IT Courses <UilAngleDown /></Link>
                        </li>
                        <li className="nav__item">
                            <Link to="/" className="nav__link">Explore <UilAngleDown /></Link>
                            {/* Resources (Books, Videos, Kindle),
                            Career Centre (CVs, Cover Letters, Tips), 
                            Zyzics IT Solutions - (for Enterprises), 
                            IT Training for Teams,
                            Join / Login.
                            */}
                        </li>
                        <li className="nav__item">
                            <Link to="/" className="nav__link">Blog</Link>
                        </li>
                        <li className="nav__item">
                            <Link to="/" className="nav__link">Contact</Link>
                        </li>
                        <li className="nav__item">
                            <UilYoutube />
                        </li>
                        <Link to="/register-eagle" className="button button__header">Enroll in a course</Link>
                    </ul>
                </div>
            </nav>
        </header>
    );
}

export default Header;
