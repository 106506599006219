import {Routes, Route} from 'react-router-dom';
import Header from './pages/layouts/Header';
import Home from './pages/public/Home';
// import Footer from './pages/layouts/Footer';

const Main = () => {
  const project = 'Zyzics';
  const slogan = 'Learn Your Dream IT Courses In Simple Clear Steps';
  const title = `${project} - ${slogan}`;

  return (
    <>
      <Header project={project} />
      <Routes>
        <Route path="/" element={<Home title={title} />} />
        {/* <Route path="/about" element={<About title={title} />} /> */}
      </Routes>
      {/* <Footer project={project} slogan={slogan} /> */}
    </>
  );
}
export default Main;
