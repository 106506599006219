// import { Link } from 'react-router-dom';

const Categories_Section = () => {
  return (
    <>
      <br />
      <section id="categories__section">
        <div className="section__text container">
            <h2>Top Categories <br />
            of Courses</h2>
        </div>
        <div className="section2__container grid">
          <div className="section2__caption container">
            {/* <h1>Learn</h1> */}
            {/* <button className="button button--link m-1" style={{marginTop: 0}}>Browse Categories</button> */}
            {/* your dream course */}
            {/* Easy, step-by-step guide */}
          </div>

          <div className="about__courses grid container">
            <div className="about__course-item">
              <span className="about__course-total">6 Courses</span>
              <h3 className="about__course-title">Programming Languages</h3>
            </div>

            <div className="about__course-item">
              <span className="about__course-total">4 Courses</span>
              <h3 className="about__course-title">Full-Stack Development</h3>
            </div>

            <div className="about__course-item">
              <span className="about__course-total">2 Courses</span>
              <h3 className="about__course-title">Website Design</h3>
            </div>

            <div className="about__course-item">
              <span className="about__course-total">2 Courses</span>
              <h3 className="about__course-title">Backend Development</h3>
            </div>

            <div className="about__course-item">
              <span className="about__course-total">2 Courses</span>
              <h3 className="about__course-title">Frontend Development</h3>
            </div>

            <div className="about__course-item">
              <span className="about__course-total">2 Courses</span>
              <h3 className="about__course-title">Mobile App Development</h3>
            </div>
          </div>
        </div>
    </section>
    </>
  )
}
export default Categories_Section
